#bootstrap-overrides button.onship-btn {
	width: 364px;
	height: 40px;
	margin: 5px 0 0;
	padding: 11px 161px 12px 164px;
	object-fit: contain;
	border-radius: 100px;
	background-color: #638dff;
}

#bootstrap-overrides a.onship-txt {
	color: #638dff !important;
	text-transform: none !important;
}

#bootstrap-overrides a.onship-link {
	width: 364px;
	height: 40px;
	flex-grow: 0;
	margin: auto;
	padding: 10px 47px 10px 45px;
	object-fit: contain;
	border-radius: 100px;
	border: solid 1.2px #638dff;
	color: #638dff !important;
}